<template>
  <v-card>
    <v-card-title>{{ $t('routes.users.index') }}</v-card-title>
    <Datatable
      get_endpoint="users"
      :delete_endpoint="hasLaravelRoute('users.delete') ? 'users' : null"
      :header_items="headers"
      :create_route="hasVueRoute('users.add') ? 'users.add' : null"
      :edit_route="hasVueRoute('users.edit') ? 'users.edit' : undefined"
      user_friendly_identifier="firstname"
      :item_on_click="
        item => {
          if (hasVueRoute('users.edit')) {
            this.$router.push({
              name: 'users.edit',
              params: { id: item.id },
            })
          }
        }
      "
      :show_delete="
        item => {
          return item.id !== $store.state.user.id && hasLaravelRoute('users.delete')
        }
      "
      :show_extra_action="
        item => {
          return item.id !== $store.state.user.id && hasLaravelRoute('users.personify')
        }
      "
      :extra_action_on_click="personifyOnClick"
      type="entity.user"
    >
      <template v-slot:extra_action>
        <v-list-item-title>
          <v-icon size="20" class="me-2">
            {{ mdiAccountConvert }}
          </v-icon>
          <span>{{ $t('common.personify') }}</span>
        </v-list-item-title>
      </template>
    </Datatable>
  </v-card>
</template>

<script>
import Datatable from '@/components/Datatable/Datatable.vue'
import { mdiAccountConvert } from '@mdi/js'

export default {
  components: {
    Datatable,
  },
  data() {
    return {
      headers: [
        {
          value: 'image',
          sortable: false,
          prevent_export: true
        },
        {
          text: 'label.id',
          value: 'id',
          search_type: 'number',
          sortable: true,
        },
        {
          text: 'label.firstname',
          value: 'firstname',
          search_type: 'text',
          sortable: true,
        },
        {
          text: 'label.middlename',
          value: 'middlename',
          sortable: true,
        },
        {
          text: 'label.lastname',
          value: 'lastname',
          search_type: 'text',
          sortable: true,
        },
        {
          text: 'label.email',
          value: 'email',
          search_type: 'text',
          sortable: true,
        },
        {
          text: 'label.created_at',
          value: 'created_at',
          search_type: 'date',
          data_format: 'date',
          sortable: true,
        },
      ],
      mdiAccountConvert: mdiAccountConvert,
    }
  },
  methods: {
    personifyOnClick(item) {
      this.$http.post('users/personify/' + item.id).then(res => {
        if (res.status == 200) {
          this.$store.commit('user/personified', true)
          this.$store.commit('user/user', res.data.user)
          this.$store.commit('notification/notify', {
            text: 'notification.personification_success',
            color: 'success',
          })
          this.$router.push('/profile')
        }
      })
    },
  },
}
</script>
