var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('routes.users.index')))]),_c('Datatable',{attrs:{"get_endpoint":"users","delete_endpoint":_vm.hasLaravelRoute('users.delete') ? 'users' : null,"header_items":_vm.headers,"create_route":_vm.hasVueRoute('users.add') ? 'users.add' : null,"edit_route":_vm.hasVueRoute('users.edit') ? 'users.edit' : undefined,"user_friendly_identifier":"firstname","item_on_click":function (item) {
        if (_vm.hasVueRoute('users.edit')) {
          this$1.$router.push({
            name: 'users.edit',
            params: { id: item.id },
          })
        }
      },"show_delete":function (item) {
        return item.id !== _vm.$store.state.user.id && _vm.hasLaravelRoute('users.delete')
      },"show_extra_action":function (item) {
        return item.id !== _vm.$store.state.user.id && _vm.hasLaravelRoute('users.personify')
      },"extra_action_on_click":_vm.personifyOnClick,"type":"entity.user"},scopedSlots:_vm._u([{key:"extra_action",fn:function(){return [_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.mdiAccountConvert)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('common.personify')))])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }